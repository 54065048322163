// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---node-modules-draftbox-co-gatsby-ghost-novela-theme-src-pages-404-tsx": () => import("./../node_modules/@draftbox-co/gatsby-ghost-novela-theme/src/pages/404.tsx" /* webpackChunkName: "component---node-modules-draftbox-co-gatsby-ghost-novela-theme-src-pages-404-tsx" */),
  "component---node-modules-draftbox-co-gatsby-ghost-novela-theme-src-pages-contact-tsx": () => import("./../node_modules/@draftbox-co/gatsby-ghost-novela-theme/src/pages/contact.tsx" /* webpackChunkName: "component---node-modules-draftbox-co-gatsby-ghost-novela-theme-src-pages-contact-tsx" */),
  "component---node-modules-draftbox-co-gatsby-ghost-novela-theme-src-pages-offline-tsx": () => import("./../node_modules/@draftbox-co/gatsby-ghost-novela-theme/src/pages/offline.tsx" /* webpackChunkName: "component---node-modules-draftbox-co-gatsby-ghost-novela-theme-src-pages-offline-tsx" */),
  "component---node-modules-draftbox-co-gatsby-ghost-novela-theme-src-templates-article-template-amp-tsx": () => import("./../node_modules/@draftbox-co/gatsby-ghost-novela-theme/src/templates/article.template.amp.tsx" /* webpackChunkName: "component---node-modules-draftbox-co-gatsby-ghost-novela-theme-src-templates-article-template-amp-tsx" */),
  "component---node-modules-draftbox-co-gatsby-ghost-novela-theme-src-templates-article-template-tsx": () => import("./../node_modules/@draftbox-co/gatsby-ghost-novela-theme/src/templates/article.template.tsx" /* webpackChunkName: "component---node-modules-draftbox-co-gatsby-ghost-novela-theme-src-templates-article-template-tsx" */),
  "component---node-modules-draftbox-co-gatsby-ghost-novela-theme-src-templates-articles-template-tsx": () => import("./../node_modules/@draftbox-co/gatsby-ghost-novela-theme/src/templates/articles.template.tsx" /* webpackChunkName: "component---node-modules-draftbox-co-gatsby-ghost-novela-theme-src-templates-articles-template-tsx" */),
  "component---node-modules-draftbox-co-gatsby-ghost-novela-theme-src-templates-author-template-tsx": () => import("./../node_modules/@draftbox-co/gatsby-ghost-novela-theme/src/templates/author.template.tsx" /* webpackChunkName: "component---node-modules-draftbox-co-gatsby-ghost-novela-theme-src-templates-author-template-tsx" */)
}

